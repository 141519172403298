import util from "../util/util";

export default class BlogService {
  listAll(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/video/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getDetails(dataId) {
    return util
      .sendApiRequest("/video/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  async getFilterList() {
    try {
      return await util.sendApiRequest("/video/filterlist", "GET", true);
    } catch (err) {
      throw err;
    }
  }
  updateViewCount(payload) {
    return util
      .sendApiRequest("/video/updateviewcount", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  blockVideo(payload) {
    return util
      .sendApiRequest("/video/blockvideo", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  unblockVideo(payload) {
    return util
      .sendApiRequest("/video/unblockvideo", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  reportVideo(payload) {
    return util
      .sendApiRequest("/report", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  updateNotification(payload) {
    return util
      .sendApiRequest("/channel/updatenotification", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
}
