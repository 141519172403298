import React, { useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Link } from "react-router-dom";
import util from "../../util/util";
import moment from "moment";
import NewsService from "../../services/NewsService";
import SocialShare from "../../shared/SocialShare";
const serv = new NewsService();
function NewsCard({ news }) {
  const globalCtx = useContext(GlobalContext);
  const [isAuthentiCated, setIsAuthentiCated] = globalCtx.auth;
  const { updateBookmark, checkBookmark } = globalCtx;
  const blockNews = async (type, id) => {
    try {
      let obj = {
        type: type,
        id: id,
      };
      await serv.blockNews(obj).then((resp) => {
        console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const updateViewCount = async (id) => {
    try {
      let obj = {
        _id: id,
      };
      await serv.updateViewCount(obj).then((resp) => {
        console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const reportNews = async (id) => {
    try {
      let obj = {
        data_id: id,
        section: 1,
      };
      await serv.reportNews(obj).then((resp) => {
        console.log(resp);
      });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="col-sm-6 col-lg-6 col-xl-3 col-xxl-2 cardsBox cardsBoxNews">
      <div className="updates_card updateCard updateCardBlog updateCardBlogCustom updateCardNews">
        {/* <Link to={"/newss/" + news._id}> */}
        <div className="updates_box upDatesBox upBlogBox newsBannerCard position-relative">
          <a onClick={() => updateViewCount(news._id)} href={news.news_link} target="_blank">
            <div className="sliderBoxImg text-center sliderBoxImgNews">
              {news.news_image ? (
                <img
                  src={news.news_image}
                  alt=""
                  className="img-fluid"
                  // style={{ height: "120px", width: "206px" }}
                />
              ) : (
                <div className="news-img-head">
                  <img
                    src="/images/svg-icons/mavefi-logo.svg"
                    alt=""
                    className="img-fluid"
                    // style={{ height: "120px", width: "206px" }}
                  />
                </div>
              )}
            </div>
          </a>
          <div className="d-flex boxHeadingGroup">
            <div className="box_heading d-flex justify-content-between box-heading-news">
              <div className="w-100">
                <p className="m-0">{moment(news?.createdAt).fromNow()}</p>
                <h4 className="m-0 newsHeading">{news.news_title}</h4>
                <p className="newsDiscription">{news.content}</p>
              </div>
              {/* <VideoAction newsDetail={news} /> */}
              <div className="modeBtn dropdown">
                <img
                  src="/images/svg-icons/vertical-dots.svg"
                  data-bs-toggle="dropdown"
                  className="img-fluid"
                  style={{ width: "10px", height: "15px" }}
                />
                <ul className="dropdown-menu videoDropDown marginTop5" style={{ width: "240px" }}>
                  <li>
                    <a
                      className="dropdown-item p-0"
                      onClick={() => updateViewCount(news._id)}
                      href={news.news_link}
                      target="_blank"
                    >
                      <img src="/images/svg-icons/www.svg" alt="share-now" className="img-fluid me-1 overwriteOwlImg" />{" "}
                      Open article
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item p-0" href={news.published_by.publisher_link} target="_blank">
                      <img
                        src="/images/svg-icons/link-news.svg"
                        alt="share-now"
                        className="img-fluid me-1 overwriteOwlImg"
                      />{" "}
                      Visit publisher site
                    </a>
                  </li>
                  {isAuthentiCated && (
                    <li>
                      <a
                        className="dropdown-item p-0"
                        href="javascript:void(0);"
                        onClick={() => {
                          updateBookmark(news._id, "news");
                        }}
                      >
                        <img
                          src="/images/svg-icons/video-bookmark.svg"
                          alt="share-now"
                          className="img-fluid me-1 overwriteOwlImg"
                        />{" "}
                        Bookmark
                      </a>
                    </li>
                  )}

                  <li>
                    <a
                      className="dropdown-item p-0"
                      href="javascript:void(0);"
                      onClick={() => {
                        navigator.clipboard.writeText(`${news.news_link}`);
                      }}
                    >
                      <img
                        src="/images/svg-icons/video-share.svg"
                        alt="share-now"
                        className="img-fluid me-1 overwriteOwlImg"
                      />{" "}
                      Share
                    </a>
                  </li>
                  {isAuthentiCated && (
                    <>
                      <hr />
                      <li>
                        <a
                          className="dropdown-item p-0"
                          href="javascript:void(0);"
                          onClick={() => blockNews("news", news._id)}
                        >
                          <img
                            src="/images/svg-icons/video-nt-interest.svg"
                            alt="share-now"
                            className="img-fluid me-1 overwriteOwlImg"
                          />{" "}
                          Not interested
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item p-0"
                          href="javascript:void(0);"
                          onClick={() => blockNews("publisher", news.published_by._id)}
                        >
                          <img
                            src="/images/svg-icons/video-not-recomend.svg"
                            alt="share-now"
                            className="img-fluid me-1 overwriteOwlImg"
                          />{" "}
                          Don’t recommend publisher
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item p-0"
                          href="javascript:void(0);"
                          onClick={() => reportNews(news._id)}
                        >
                          <img
                            src="/images/svg-icons/video-report.svg"
                            alt="share-now"
                            className="img-fluid me-1 overwriteOwlImg"
                          />{" "}
                          Report
                        </a>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </div>

            <div className="youtubeViewCount newsChannelCount">
              <div className="newsBottom">
                <div className="newsBottomTitle">
                  <div className="web-img"></div>
                  <h4 className="m-0">{news.published_by.added_by}</h4>
                </div>
                <div className="cardBtnIcon">
                  <div className="d-flex headerBtnSec">
                    {isAuthentiCated && (
                      <div className="modeBtn">
                        <a
                          href="javascript:void(0)"
                          className=""
                          onClick={() => {
                            updateBookmark(news._id, "news");
                          }}
                        >
                          {checkBookmark(news._id) ? (
                            <img
                              src="/images/svg-icons/bookmark-active.svg"
                              alt="bookmark"
                              className="img-fluid bookmarkActive"
                            />
                          ) : (
                            <img
                              src="/images/svg-icons/bookmark-normal.svg"
                              alt="bookmark"
                              className="img-fluid bookmarkNormal"
                            />
                          )}
                          {/* <img
                      src="/images/svg-icons/bookmark-hover.svg"
                      alt="bookmark"
                      className="img-fluid bookmarkHover"
                    /> */}
                        </a>
                      </div>
                    )}
                    <SocialShare
                      twitter={{
                        text: news.news_title,
                        url: news.news_link,
                      }}
                      facebook={{
                        title: news.news_title,
                        url: news.news_link,
                      }}
                      website={{
                        title: news.news_title,
                        url: news.news_link,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div
                className="box_heading box_post d-flex align-items-center justify-content-between"
                style={{ marginTop: "auto" }}
              >
                <div className="postingName">
                  <img
                    src="/images/svg-icons/doodle.svg"
                    alt="doodle"
                    className="img-fluid"
                  />
                  <p className="mb-0">{news.channel_name}</p>
                </div>
                <div className="postDay">
                  <p className="mb-0">{util.timeSince(news.publishedAt)}</p>
                </div>
              </div> */}
          </div>
        </div>
        {/* </Link> */}
      </div>
    </div>
  );
}

export default NewsCard;
