import { useContext, useEffect, useState } from "react";
import CourseCard from "../Courses/CourseCard";
import BlogCard from "../Blogs/BlogCard";
import GlossaryCard from "../Glossary/GlossaryCard";
import AccountCard from "../Account/AccountCard";
import GlobalContext from "../../context/GlobalContext";
import UserService from "../../services/UserService";
import OwlCarousel from "react-owl-carousel";
import { useParams } from "react-router-dom";
import VideoCard from "../Videos/VideoCard";
import NewsCard from "../News/NewsCard";
const userServ = new UserService();

function SearchResult() {
  const params = useParams();
  const globalCtx = useContext(GlobalContext);
  const [searchDtl, setSearchDtl] = globalCtx.searchDtl;
  const [blogs, setBlogs] = useState([]);
  const [courses, setCourses] = useState([]);
  const [glossary, setGlossary] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [video, setVideo] = useState([]);
  const [news, setNews] = useState([]);
  const [totalResult, setTotalResult] = useState(0);

  useEffect(() => {
    searchResultList(params.id);
  }, [params.id]);
  const searchResultList = async (searchTxt) => {
    try {
      let obj = {
        searchText: searchTxt,
      };
      let response = await userServ.listAllSearchData(obj);
      if (response && response.megaResult) {
        console.log(response.megaResult);
        setCourses(response.megaResult.course.data);
        setBlogs(response.megaResult.blog.data);
        setGlossary(response.megaResult.glossary.data);
        setProfiles(response.megaResult.user.data);
        setVideo(response.megaResult.video.data);
        setNews(response.megaResult.news.data);
        setTotalResult(
          response.megaResult.course.total +
            response.megaResult.blog.total +
            response.megaResult.glossary.total +
            response.megaResult.user.total +
            response.megaResult.video.total +
            response.megaResult.news.total
        );
      }
    } catch (err) {
      throw err;
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="bookmarkSectionData">
        <div className="glossaryTitleSec">
          <div className="row">
            <div className="col-md-12">
              <div className="titleCard titleAboutCard card padding20 radius12">
                <h4 className="mb-2 searchKeyHeading">
                  <img src="/images/svg-icons/searchIcon.svg" alt="book" className="img-fluid searchImgHeight" /> Search
                  results for “{params.id}”
                </h4>
                <p className="mb-0 searchPara">{totalResult} items found</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Glossary</h5>
            </div>
          </div>
          <div className="bookMarkCard">
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {profiles.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag"
                      id="bookMarkSlider"
                      navText={[
                        '<img src="/images/svg-icons/next.svg">',
                        '<img src="/images/svg-icons/next.svg">',
                      ]}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 3,
                        },
                        1200: {
                          items: 4,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {glossary.map((g) => {
                        return (
                          <div className="item">
                            <GlossaryCard key={g._id} glossary={g} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>
                        No results found. Looks like you search did not match
                        any of the words in our glossary.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Accounts</h5>
            </div>
          </div>
          <div className="bookMarkCard">
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {profiles.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag zIndex2"
                      id="bookMarkSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 3,
                        },
                        1200: {
                          items: 4,
                        },
                        1440: {
                          items: 4,
                        },
                      }}
                    >
                      {profiles.map((c) => {
                        return (
                          <div className="item">
                            <AccountCard account={c} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of our courses.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Videos</h5>
            </div>
          </div>
          <div className="bookMarkCard" style={{ position: "relative", zIndex: 4 }}>
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {news.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag zIndex2"
                      id="bookMarkSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 4,
                        },
                        1200: {
                          items: 5,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {video.map((c) => {
                        return (
                          <div className="item">
                            <VideoCard video={c} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of our videos.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from News</h5>
            </div>
          </div>
          <div className="bookMarkCard" style={{ position: "relative", zIndex: 3 }}>
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {news.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag zIndex2"
                      id="bookMarkSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 4,
                        },
                        1200: {
                          items: 5,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {news.map((c) => {
                        return (
                          <div className="item">
                            <NewsCard news={c} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of our news.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Courses</h5>
            </div>
          </div>
          <div className="bookMarkCard" style={{ position: "relative", zIndex: 2 }}>
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {courses.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag zIndex2"
                      id="bookMarkSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 3,
                        },
                        1200: {
                          items: 4,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {courses.map((c) => {
                        return (
                          <div className="item">
                            <CourseCard key={c._id} course={c} bookmark={false} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of our courses.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmarkSectionInner courses">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Glossary</h5>
            </div>
          </div>
          <div className="bookMarkCard" style={{ position: "relative", zIndex: 1 }}>
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {glossary.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel bookMarkSlider commonSlider owl-loaded owl-drag"
                      id="bookMarkSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 3,
                        },
                        1200: {
                          items: 4,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {glossary.map((g) => {
                        return (
                          <div className="item">
                            <GlossaryCard key={g._id} glossary={g} />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of the words in our glossary.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bookmarkSectionInner emptySlider blogPost">
          <div className="bookmarkTitleOuter d-flex align-items-center justify-content-between">
            <div className="bookmarkTitle">
              <h5 className="mb-0 searchDivHeading">Results from Blog Posts</h5>
            </div>
          </div>
          <div className="bookMarkCard">
            <div className="bookMarkCardList">
              <div className="bookMark_card_section mb-3">
                <div className="row">
                  {blogs.length > 0 ? (
                    <OwlCarousel
                      className="owl-carousel blogSlider commonSlider owl-loaded owl-drag"
                      id="blogSlider"
                      navText={['<img src="/images/svg-icons/next.svg">', '<img src="/images/svg-icons/next.svg">']}
                      loop={false}
                      margin={15}
                      responsiveClass={true}
                      dots={false}
                      nav={true}
                      responsive={{
                        0: {
                          items: 1,
                        },
                        350: {
                          items: 1,
                        },
                        450: {
                          items: 2,
                        },
                        575: {
                          items: 2,
                        },
                        768: {
                          items: 3,
                        },
                        992: {
                          items: 3,
                        },
                        1200: {
                          items: 4,
                        },
                        1440: {
                          items: 5,
                        },
                      }}
                    >
                      {blogs.map((b) => (
                        <div>
                          <BlogCard key={b._id} blog={b} />
                        </div>
                      ))}
                    </OwlCarousel>
                  ) : (
                    <div className="searchResultEmpty">
                      <p>No results found. Looks like you search did not match any of our articles.</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchResult;
